import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { API, CLIENT } from '../../_helpers/Constants';

export default class Graphpolyline extends Component {
    constructor(props) {
        super(props);
        const currentDate = new Date();
        this.state = {
            dateFrom: props.dateFrom || '',
            dateTo: props.dateTo || '',
            title: props.title,
            width: props.width || 300,
            height: props.height || 300,
            labels: [],
            values: [],
            isLoading: false
        };
    }

    componentDidMount() {
        this.getDataResult();
    }

    forceUpdateComponent(dateFrom, dateTo) {
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo
        }, () => {
            this.getDataResult();
        });
    }

    getDataResult() {
        this.setState({ isLoading: true });
        const urls = `${API}analysis/getcharttrendnews?username=${localStorage.getItem('_username')}&token=${localStorage.getItem('_token')}&subdomain_id=${CLIENT}&date_from=${this.state.dateFrom}&date_to=${this.state.dateTo}`;
        
        axios.get(urls)
            .then(response => {
                if (response.data.status === 200) {
                    const labelArr = response.data.data.map(item => item.tanggal);
                    const valueArr = response.data.data.map(item => parseInt(item.jumlah));
                    this.setState({
                        labels: labelArr,
                        values: valueArr
                    });
                }
                this.setState({ isLoading: false });
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        const data = {
            labels: this.state.labels,
            datasets: [
                {
                    label: 'Trend News',
                    data: this.state.values,
                    fill: false,
                    borderColor: '#36A2EB',
                    tension: 0.1
                }
            ]
        };

        const options = {
            maintainAspectRatio: true,
            responsive: true,
            title: {
                display: true,
                text: this.state.title,
                fontSize: 20
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        };

        return (
            <div className='bdz-graph-container'>
                <Line
                    data={data}
                    options={options}
                    width={this.props.width}
                    height={this.props.height}
                />
                {this.state.isLoading &&
                    <FontAwesomeIcon
                        style={{ marginRight: 5, alignSelf: "center" }}
                        icon={faSpinner}
                        spin
                    />
                }
            </div>
        );
    }
}