import React, { Component } from 'react';
import axios from 'axios';
import { API, CLIENT } from '../../_helpers/Constants';

export default class TableTone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toneData: {
                positive: 0,
                neutral: 0,
                negative: 0
            }
        };
    }

    componentDidMount() {
        this.getData(this.props.dateFrom, this.props.dateTo);
    }

    forceUpdateComponent = (dateFrom, dateTo) => {
        this.getData(dateFrom, dateTo);
    }

    getData(dateFrom, dateTo) {
        var url =  API + "analysis/getcharttone?username="+ localStorage.getItem('_username')
                  + "&token="+ localStorage.getItem('_token') 
                  + "&subdomain_id=" + CLIENT 
                  + "&date_from=" + dateFrom
                  + "&date_to=" + dateTo
        
        axios.get(url)
            .then(response => {
                if (response.data.status === 200) {
                    const toneData = {
                        positive: 0,
                        neutral: 0,
                        negative: 0
                    };

                    // Process the response data
                    response.data.data.forEach(item => {
                        if (item.berita_tone == 1) {
                            toneData.positive = item.total;
                        } else if (item.berita_tone == 0) {
                            toneData.neutral = item.total;
                        } else if (item.berita_tone == -1) {
                            toneData.negative = item.total;
                        }
                    });

                    this.setState({ toneData });
                }
            })
            .catch(error => console.error('Error fetching data:', error));
    }

    render() {
        const { toneData } = this.state;
        const total = toneData.positive + toneData.neutral + toneData.negative;

        const rows = [
            {
                title: "Berita Positif",
                positive: toneData.positive,
                neutral: 0,
                negative: 0,
                total: toneData.positive
            },
            {
                title: "Berita Netral",
                positive: 0,
                neutral: toneData.neutral,
                negative: 0,
                total: toneData.neutral
            },
            {
                title: "Berita Negatif",
                positive: 0,
                neutral: 0,
                negative: toneData.negative,
                total: toneData.negative
            }
        ];

        return (
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead style={{ backgroundColor: '#f0f0f0' }}>
                        <tr>
                            <th></th>
                            <th>Jumlah Positif</th>
                            <th>Jumlah Netral</th>
                            <th>Jumlah Negatif</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => (
                            <tr key={index}>
                                <td>{row.title}</td>
                                <td>{row.positive}</td>
                                <td>{row.neutral}</td>
                                <td>{row.negative}</td>
                                <td>{row.total}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}
