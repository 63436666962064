import React, { Component } from 'react';
import axios from 'axios';
import { API, CLIENT } from '../../_helpers/Constants';

export default class TableTrenNews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newsData: {
                online: 0,
                tv: 0,
                cetak: 0
            }
        };
    }

    forceUpdateComponent(dateFrom, dateTo){
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo
        }, () => {
            this.getData(this.state.dateFrom, this.state.dateTo);
        })
    }

    getData(dateFrom, dateTo) {
        var url = API + "analysis/getchartjenismedia?username="+ localStorage.getItem('_username')
                + "&token="+ localStorage.getItem('_token') 
                + "&subdomain_id=" + CLIENT 
                + "&date_from=" + dateFrom
                + "&date_to=" + dateTo
        console.log('url: ' + url + '')
        axios.get(url)
            .then(response => {
                if (response.data.status === 200) {
                    const newsData = {
                        online: 0,
                        tv: 0,
                        cetak: 0
                    };

                    response.data.data.forEach(item => {
                        if (item.berita_jenis == "1") {
                            newsData.online = parseInt(item.jumlah);
                        } else if (item.berita_jenis == "2") {
                            newsData.tv = parseInt(item.jumlah);
                        } else if (item.berita_jenis == "3") {
                            newsData.cetak = parseInt(item.jumlah);
                        }
                    });

                    this.setState({ newsData });
                }
            })
            .catch(error => console.error('Error fetching data:', error));
    }

    render() {
        const { newsData } = this.state;
        const total = newsData.online + newsData.tv + newsData.cetak;

        const rows = [
            {
                title: "Media Online",
                online: newsData.online,
                tv: 0,
                cetak: 0,
                total: newsData.online
            },
            {
                title: "Media TV",
                online: 0,
                tv: newsData.tv,
                cetak: 0,
                total: newsData.tv
            },
            {
                title: "Media Cetak",
                online: 0,
                tv: 0,
                cetak: newsData.cetak,
                total: newsData.cetak
            }
        ];

        return (
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead style={{ backgroundColor: '#f0f0f0' }}>
                        <tr>
                            <th></th>
                            <th>Jumlah Berita Online</th>
                            <th>Jumlah Berita TV</th>
                            <th>Jumlah Berita Cetak</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => (
                            <tr key={index}>
                                <td>{row.title}</td>
                                <td>{row.online}</td>
                                <td>{row.tv}</td>
                                <td>{row.cetak}</td>
                                <td>{row.total}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}
