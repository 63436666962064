import React, { Component } from 'react';
import axios from 'axios';
import { API, CLIENT } from '../../_helpers/Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default class TableToneMedia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: false
        };
    }

    componentDidMount() {
        this.forceUpdateComponent(this.props.dateFrom, this.props.dateTo);
    }

    forceUpdateComponent = (dateFrom, dateTo) => {
        this.setState({ isLoading: true });
        var url =  API + "analysis/getchartonemedia?username="+ localStorage.getItem('_username')
                          + "&token="+ localStorage.getItem('_token') 
                          + "&subdomain_id=" + CLIENT 
                          + "&date_from=" + dateFrom
                          + "&date_to=" + dateTo
        
        axios.get(url)
            .then(response => {
                if (response.data.status === 200) {
                    // Group data by media and process tone values
                    const mediaMap = new Map();
                    
                    response.data.data.forEach(item => {
                        if (!mediaMap.has(item.media_nama)) {
                            mediaMap.set(item.media_nama, {
                                media: item.media_nama,
                                positive: 0,
                                neutral: 0,
                                negative: 0
                            });
                        }
                        
                        const mediaData = mediaMap.get(item.media_nama);
                        
                        // Process tone array
                        if (Array.isArray(item.tone)) {
                            item.tone.forEach(toneItem => {
                                const total = parseInt(toneItem.total) || 0;
                                
                                if (toneItem.berita_tone == 1) {
                                    mediaData.positive += total;
                                } else if (toneItem.berita_tone == 0) {
                                    mediaData.neutral += total;
                                } else if (toneItem.berita_tone == -1) {
                                    mediaData.negative += total;
                                }
                            });
                        }
                    });

                    // Convert to array and calculate totals
                    const processedData = Array.from(mediaMap.values())
                        .map(item => ({
                            ...item,
                            total: item.positive + item.neutral + item.negative
                        }))
                        .sort((a, b) => b.total - a.total)
                        .slice(0, 5);  // Get top 5 media

                    this.setState({ 
                        data: processedData,
                        isLoading: false 
                    });
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        return (
            <div className="table-responsive">
                {this.state.isLoading ? (
                    <div className="text-center">
                        <FontAwesomeIcon icon={faSpinner} spin />
                    </div>
                ) : (
                    <table className="table table-bordered">
                        <thead style={{ backgroundColor: '#f0f0f0' }}>
                            <tr>
                                <th>Media</th>
                                <th>Jumlah Positif</th>
                                <th>Jumlah Netral</th>
                                <th>Jumlah Negatif</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.media}</td>
                                    <td>{item.positive}</td>
                                    <td>{item.neutral}</td>
                                    <td>{item.negative}</td>
                                    <td>{item.total}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        );
    }
}
