import React, { Component } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { API, CLIENT } from '../../_helpers/Constants';

export default class TableToneAnalysis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: false
        };
    }

    componentDidMount() {
        this.forceUpdateComponent(this.props.dateFrom, this.props.dateTo);
    }

    forceUpdateComponent = (dateFrom, dateTo) => {
        this.setState({ isLoading: true });
        var url =  API + "analysis/getchartmedia?username="+ localStorage.getItem('_username')
                                                  + "&token="+ localStorage.getItem('_token') 
                                                  + "&subdomain_id=" + CLIENT 
                                                  + "&date_from=" + dateFrom
                                                  + "&date_to=" + dateTo
        
        axios.get(url)
            .then(response => {
                if (response.data.status === 200) {
                    const processedData = response.data.data
                        .map(item => ({
                            media: item.media_nama,
                            total: item.total
                        }))
                        .sort((a, b) => b.total - a.total)
                        .slice(0, 5);  // Get top 5 media

                    this.setState({ 
                        data: processedData,
                        isLoading: false 
                    });
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        return (
            <div className="table-responsive">
                {this.state.isLoading ? (
                    <div className="text-center">
                        <FontAwesomeIcon icon={faSpinner} spin />
                    </div>
                ) : (
                    <table className="table table-bordered">
                        <thead style={{ backgroundColor: '#f0f0f0' }}>
                            <tr>
                                <th>Analysis</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.media}</td>
                                    <td>{item.total}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        );
    }
}
