import React, { Component } from 'react';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import axios from 'axios';
import { API, CLIENT } from '../../_helpers/Constants';

export default class GraphMediaTv extends Component {
    constructor(props) {
        super(props);
        const currentDate = new Date();
        this.state = {
            chartData: {
                labels: [],
                datasets: [{
                    label: 'Jumlah Berita',
                    data: [],
                    backgroundColor: 'rgba(0, 255, 26, 0.6)',
                    borderColor: 'rgb(54, 235, 111)',
                    borderWidth: 1
                }]
            },
            dateFrom: props.dateFrom || '',
            dateTo: props.dateTo || '',
        }
    }

    componentDidMount() {
        this.getData(this.state.dateFrom, this.state.dateTo);
    }

    forceUpdateComponent = (dateFrom, dateTo) => {
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo
        }, () => {
            this.getData(this.state.dateFrom, this.state.dateTo);
        });
    }

    getData(dateFrom, dateTo) {
        const url = `${API}analysis/getgrafiktoptentv?username=${localStorage.getItem('_username')}&token=${localStorage.getItem('_token')}&subdomain_id=${CLIENT}&date_from=${dateFrom}&date_to=${dateTo}`;
        
        axios.get(url)
            .then(response => {
                if (response.data.status === 200) {
                    const labels = response.data.data.map(item => item.media_nama);
                    const data = response.data.data.map(item => parseInt(item.jumlah));

                    this.setState({
                        chartData: {
                            ...this.state.chartData,
                            labels: labels,
                            datasets: [{
                                ...this.state.chartData.datasets[0],
                                data: data
                            }]
                        }
                    });
                }
            })
            .catch(error => console.error('Error fetching data:', error));
    }

    render() {
        const options = {
            indexAxis: 'y',
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        stepSize: 10
                    }
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            },
            legend: {
                display: true,
                position: 'top'
            }
        };

        return (
            <div>
                <h5>{this.props.title}</h5>
                <div style={{ height: 300, width: this.props.width }}>
                    <HorizontalBar data={this.state.chartData} options={options} />
                </div>
            </div>
        )
    }
}
