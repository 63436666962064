
import React, { Component } from 'react';
import { DateHelper } from '../../_helpers/DateHelper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';
import { API, CLIENT, ASSETS_URL } from '../../_helpers/Constants';

import { HorizontalBar } from 'react-chartjs-2';
export default class Graphtopissue extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: '',
      data: [],
      dateFrom: props.dateFrom || DateHelper(),
      dateTo: props.dateTo || DateHelper(),
      title: props.title,

      width: props.width || 300,
      height: props.height || 300,

      labels: [],
      values: [],
    }
  }

  componentDidMount() {
    this.getDataResult();
  }

  forceUpdateComponent(dateFrom, dateTo) {
    this.setState({
      dateFrom: dateFrom,
      dateTo: dateTo,
    }, () => {
      this.getDataResult();
    })
  }


  getDataResult() {
    this.setState({ isLoading: true });
    var self = this;
    var urls = API + "analysis/getcharttopissues?username=" + localStorage.getItem('_username')
      + "&token=" + localStorage.getItem('_token')
      + "&subdomain_id=" + CLIENT
      + "&date_from=" + this.state.dateFrom
      + "&date_to=" + this.state.dateTo
      + "&limit=5"

    axios({
      method: 'get',
      url: urls,
    }).then(function (response) {
      if (response.data.status == 200) {

        let labelArr = [];
        response.data.data.map((item, i) => {
          labelArr.push(item.berita_isu);
        });

        let valueArr = [];
        response.data.data.map((item, i) => {
          valueArr.push(item.total);
        })
        self.setState({ labels: labelArr, values: valueArr });
      }
      self.setState({ isLoading: false });
    }).catch(function (error) {
      self.setState({ isLoading: false });
    });
  }



  render() {


    const data = {
      labels: this.state.labels,
      datasets: [{
        data: this.state.values,
        label: 'Berita',
        backgroundColor: 'rgba(236, 129, 37, 0.62)',
        borderWidth: 1,
      }]

    };

    const options = {
      maintainAspectRatio: true,
      responsive: true,
      title: {
        display: true,
        text: this.state.title,
        fontSize: 15
      },
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 10,
          fontSize: 12
        }
      },
      scales: {
        xAxes: [{
            ticks: {
                beginAtZero: true,
                min: 0,
                stepSize: 10
            }
        }],
        yAxes: [{
            ticks: {
                beginAtZero: true
            }
        }]
    },
    legend: {
        display: true,
        position: 'top'
    }
    };



    return (
      <div className='bdz-graph-container'>
        <HorizontalBar ref="chart" width={this.props.width} height={this.props.height} data={data} options={options} />
        {this.state.isLoading ? <FontAwesomeIcon style={{ marginRight: 5, alignSelf: "center" }} icon={faSpinner} spin /> : ''}
      </div>
    )
  }

}

