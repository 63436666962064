import React,{ Component } from 'react';
import { DateHelper } from '../../_helpers/DateHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { API, CLIENT } from '../../_helpers/Constants';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default class TrenNews extends Component {
    constructor(props){
        super(props);
        this.state = {
            id : '',
            data : [],
            dateFrom: props.dateFrom || '',
            dateTo: props.dateTo || '',
            title : props.title,
            width : props.width || 300,
            height : props.height || 300,
            labels : [],
            values : [],
            colors : [],
        }
    }

    forceUpdateComponent(dateFrom, dateTo){
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo
        }, () => {
            this.getDataResult(this.state.dateFrom, this.state.dateTo);
        })
    }

    componentDidMount(){
        this.getDataResult(this.state.dateFrom, this.state.dateTo);
    }

    getDataResult(){
        this.setState({isLoading : true});
        var self = this;
        var urls = API + "analysis/getchartjenismedia?username="+ localStorage.getItem('_username')
            + "&token="+ localStorage.getItem('_token') 
            + "&subdomain_id=" + CLIENT 
            + "&date_from=" + this.state.dateFrom
            + "&date_to=" + this.state.dateTo;

        axios({
            method: 'get',
            url: urls,
        }).then(function (response) {
            if(response.data.status == 200){                
                let labelArr = [];    
                let colorArr = [];
                let valueArr = [];

                const totalNews = response.data.data.reduce((sum, item) => sum + parseInt(item.jumlah), 0);
                
                response.data.data.map((item, i) => {
                    const percentage = ((parseInt(item.jumlah) / totalNews) * 100).toFixed(1);
                    valueArr.push(parseFloat(percentage));
                    
                    labelArr.push(item.berita_jenis == 1 ? 'Online' : item.berita_jenis == '2' ? 'TV' : 'Cetak');
                });
                
                self.setState({ 
                    labels: labelArr, 
                    values: valueArr
                });
            }
            self.setState({isLoading : false});
        }).catch(function (error) {
            self.setState({isLoading : false});
        });
    }

    render(){   
        const data = {
            labels: this.state.labels,
            datasets: [{
                data: this.state.values,
                backgroundColor: ['#FFA500', '#36A2EB', '#4CAF50'],
                hoverBackgroundColor: ['#FFA500', '#36A2EB', '#4CAF50']
            }]
        }; 

        const options = {
            maintainAspectRatio: true,
            responsive: true,
            title:{
                display: true,
                text: this.state.title,
                fontSize: 20
            },
            legend: {
                display: true,
                position: 'right',
            },
            plugins: {
                datalabels: {
                    formatter: (value) => {
                        return `${value}%`;
                    },
                    color: '#fff',
                    font: {
                        weight: 'bold',
                        size: 12
                    }
                }
            }
        }

        return (
            <div className='bdz-graph-container'>
                <Pie ref="chart" 
                    width={this.props.width} 
                    height={this.props.height} 
                    data={data} 
                    options={options}
                    plugins={[ChartDataLabels]}  
                />  
                {this.state.isLoading ? 
                    <FontAwesomeIcon 
                        style={{marginRight:5, alignSelf:"center"}} 
                        icon={faSpinner} 
                        spin
                    /> : '' 
                }
            </div>
        ) 
    }
}
